.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1eb2a6; 
  color: white;
  border: none;
  border-radius: 50%;
  width: 0;
  height: 60px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: unset;
  span{
    padding: 12px 0 0 0;
  }
}
  
  .scroll-to-top:hover {
    background-color: #1eb2a6; 
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  
  .scroll-to-top:focus {
    outline: none; /* Remove outline when focused */
  }

  @media screen and (max-width: 768px) {
    .scroll-to-top {
      bottom: 11px;
      right: 0px;
    }
  }
  