/*--------head------------*/
.header-navBar{
  display: flex;
  flex-direction: column;
  background: transparent;
  position: absolute;
  padding: 0px !important;
  margin: 0px;
  width: 100%;
  top: 0px;
}
.head {
  padding: 20px 0;
  color: #fff;
  .headerLogo {
    position: absolute;
    left: 49px;
    top: 12px;
    height: 80px;
    width: 85px;
}
.motoFont{
  font-style: italic;
  font-size: 14px;
}
}
.logo{
  cursor: pointer;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #1eb2a6;
}
.start {
  background-color: #1eb2a6;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .header-navBar{
    display: flex;
    flex-direction: column;
    background: transparent;
    position: absolute;
    padding: 0px !important;
    margin: 0px;
    width: 100%;
  }
  .head{
    .motoFont{
      font-style: italic;
      font-size: 11px;
    }
  }
 
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #1eb2a6;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #1eb2a6;
  }
  .headerLogo{
    display: none;
  }
}
.social{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}

@media screen and (max-width: 768px) {
  .social{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
  .heading h3{
    margin-top: 4rem;
  }
}

