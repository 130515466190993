.aboutHome {
  height: auto;
}
.aboutHome #heading {
  text-align: left;
  padding: 0;
}
.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutHome .right {
  padding: 80px 50px;
}
.aboutHome .items {
  margin-top: 50px;
}
.aboutHome .item {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
}
.aboutHome img {
  width: 70px;
  height: 70px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome .item p {
  color: #999999;
}
.aboutHome .item:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome .item:hover p {
  color: #fff;
}
/*----------awrapper------------*/
.awrapper {
  background-image: url("../../../public/images/awrapper.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 35vh;
  color: #fff;
  margin-top: 45px;
}
.awrapper .box {
  padding: 70px 0;
}
.awrapper img {
  margin-right: 30px;
}
.awrapper h1 {
  font-size: 50px;
}
.awrapper h3 {
  font-size: 20px;
  font-weight: 500;
}

.aboutUs {
  margin-top: 10%;
  display: grid;
  grid-template-columns: 1fr;
  h1{
    font-size: 30px;
    font-weight: 400;
  }
}

.aboutUs h1 {
  text-align: center;
  margin-top: 10px;
}

.vision-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vision {
  .visionHead {
    font-style: normal;
    color: #999999;
  }

    text-align: center;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    font-style: italic;
    font-weight: 600;
    color: #1eb2a6;
    font-weight: 600;
    font-size: 15px;
  
  

}

.aboutUsCard {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
}

.imageContainer {
  text-align: center;
}

.cardImage {
  width: 70%;
  height: auto;
  border-radius: 8px;
}

.textContainer1{
  padding: 0 0 0 45px;
  p {
    color: grey;
    line-height: 1.5;
    font-size: 16px;
    text-align: justify;
  }
}

.textContainer{
 padding: 0 45px 0 0;
 p {
  color: grey;
  line-height: 1.5;
  font-size: 16px;
  text-align: justify;
}
}
.activities {
  margin-top: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .activitiesList{
    margin-top: 20px;
  }
}

.activities ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activities li {
  padding: 10px 0;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
}

.process{
  margin-top: 55px;
  text-align: -webkit-center;
  .processCard {
    display: grid;
    grid-template-columns: repeat(1, 3fr);
    grid-gap: 35px;
    width: 60%;
    text-align: center;
    height: auto;
    background: lightgrey;
    border-radius: 10px;
    padding: 20px;
      p{
        text-align: justify
      }
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }
}

/* Desktop Screens */
@media (max-width: 1279px) {
  /* .vision {
    max-width: 800px;
    font-size: 18px;
  }

  .textContainer p {
    font-size: 18px;
  } */
}


/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
  }
  .aboutHome .row {
    width: 100%;
  }
  .awrapper {
    height: 50vh;
  }
  .aboutUs {
    margin-top: 40%;
  }

  .vision-container{
    padding: 0 15px 0 15px;
  }
  .card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .textContainer1,.textContainer{
    padding: 15px;
  }
  .cardImage {
    width: 100%;
  }
  .process {
    .processCard {
    padding: 20px;
    width: 100%;
  }
}
  
}

